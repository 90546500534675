import { USER_PREFERENCES } from "../actions/types";

const userPreferencesReducer = (state = [], action) => {
	switch (action.type) {
		case USER_PREFERENCES:
			return action.payload;
		default:
			return state;
	}
};

export default userPreferencesReducer;