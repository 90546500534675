import {
	CANCEL_SELECTED_SERVICES,
	SELECT_ALL_SERVICES,
	SELECT_SERVICE_ITEM,
	SET_SERVICE_ITEMS,
	SORT_AND_FILTERS_SERVICES
} from "../../actions/types";

const serviceInitialState = {
	selectedItems: [],
	isAllItemsSelected: false,
	items: [],
	excludeIds: [],
	sortAndFilter: {
		sort: null,
		filter: {
			brand: [],
			unitPrice: { from: "", to: "" },
			retailPrice: { from: "", to: "" },
			tags: [],
			filterApply: false
		}
	}
};

export default function serviceReducer(state = serviceInitialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SELECT_SERVICE_ITEM: {
			let newIdList = [];
			let newItemsCount = state.selectedItemCount;
			let newExcludeIds = state.excludeIds;

			const newitems = state.items.map(service => {
				if (service.id == payload) {
					if (service.checked) {
						newItemsCount--;
						if (state.isAllItemsSelected) {
							if (state.excludeIds.indexOf(service.id) == -1)
								newExcludeIds.push(service.id);
						}
					} else {
						newItemsCount++;
						if (state.isAllItemsSelected) {
							newExcludeIds = state.excludeIds.filter(id => payload !== id);
						}
					}
					service.checked = !service.checked;
				}
				return service;
			});
			const isItemSelected = state.selectedItems.filter(id => id === payload);

			if (isItemSelected.length === 0) {
				newIdList = state.selectedItems.concat(payload);
			} else {
				newIdList = state.selectedItems.filter(item => item !== payload);
			}

			return {
				...state,
				items: newitems,
				selectedItems: newIdList,
				selectedItemCount: newItemsCount,
				excludeIds: newExcludeIds
			};
		}
		case CANCEL_SELECTED_SERVICES: {
			const cancelleditems = state.items.map(service => {
				if (Object.prototype.hasOwnProperty.call(service, "checked")) {
					delete service.checked;
				}
				return service;
			});
			return {
				...state,
				selectedItems: [],
				items: cancelleditems,
				isAllItemsSelected: false,
				selectedItemCount: 0,
				excludeIds: []
			};
		}

		case SET_SERVICE_ITEMS: {
			let newProducts = [];
			let selectedItemCount = state.selectedItemCount;
			const itemListForSelection = [];

			if (state.isAllItemsSelected) {
				newProducts = payload.map(service => {
					if (service.recordStatus !== 1) {
						itemListForSelection.push(service.id);
						service.checked = true;
					}
					return service;
				});
			} else {
				if (state.selectedItems.length > 0) {
					const newPayload = payload.map(item => {
						if (state.selectedItems.indexOf(item.id) > -1) {
							itemListForSelection.push(item.id);
							item.checked = true;
						}
						return item;
					});
					newProducts = newPayload;
				} else {
					newProducts = payload;
					selectedItemCount = 0;
				}
			}
			return {
				...state,
				items: newProducts,
				selectedItems: itemListForSelection,
				selectedItemCount
			};
		}

		case SELECT_ALL_SERVICES: {
			const itemListForSelection = [];
			const checkeditems = state.items.map(service => {
				if (service.recordStatus !== 1) {
					itemListForSelection.push(service.id);
					service.checked = true;
				}
				return service;
			});
			return {
				...state,
				items: checkeditems,
				selectedItems: itemListForSelection,
				isAllItemsSelected: true,
				selectedItemCount: payload,
				excludeIds: []
			};
		}
		case SORT_AND_FILTERS_SERVICES:
			return {
				...state,
				sortAndFilter: payload
			};
		default:
			return state;
	}
}
