import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userPreferencesReducer from "./userPreferencesReducer";
import integrationStatusReducer from "./integrationStatusReducer";
import { settingReducer } from "./setting_reducer";
import { toasts } from "./toasts";
import { roleReducer } from "./role_reducer";
import { downloadFile } from "./export";
import { exportStatus } from "./export_status";
import { jobs } from "./jobsReducer";
import catalogReducer from "./catalogReducer";

const rootReducer = combineReducers({
	userData: authReducer,
	UserPreferences: userPreferencesReducer,
	integrationStatus: integrationStatusReducer,
	setting: settingReducer,
	role: roleReducer,
	catalog: catalogReducer,
	toasts,
	downloadFile,
	exportStatus,
	jobs
});

export default rootReducer;
